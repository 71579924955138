
/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        if($('a.button.acf-add-checkbox').length){
          $('a.button.acf-add-checkbox').text('Other');
        }

        if($('form .search-box').length){

          // remove 'other' option from institutions dropdown
          $("form .search-box #institution option[value='2435']").remove();
          $("form .search-box #institution option[value='2598']").remove();

          // on change
          $( "select[name='searchType']" ).change(function() {
            var selectedFilter = this.value;
            updateSubfilter(selectedFilter);
            $('.subfilter input[name=query]').val('');
          });

          // on load
          $(document).ready(function() {
            var selectedFilter =  $( "select[name='searchType']" ).val();
            updateSubfilter(selectedFilter);
          });

          function updateSubfilter( selectedFilter ){
            // hide all
            $('.subfilter input, .subfilter select').hide();
            // update based on selected
            switch(selectedFilter) {
              default:
              case 'course':
                $('.subfilter input[name=query]').show();
                break;
              case 'subject':
                $('.subfilter input[name=query]').show();
                break;
              case 'institution':
                $('.subfilter select[name=institution]').show();
                break;
              case 'college':
                $('.subfilter select[name=college]').show();
                break;
            }
          }

          $('.subfilter select[name=institution]').change(function() {
              this.form.submit();
          });

          $('.subfilter select[name=college]').change(function() {
              this.form.submit();
          });
          
        }

        $('span.acf-required').append('<span class="d-none">(Required)</span>');

        $('.navbar-toggler').click(function(event) {
          $(this).parent().toggleClass('show');
        });

        $('form.af-form').attr('autocomplete', 'nope');
        $('form.af-form input').attr('autocomplete', 'nope');

        // bootstrap4 menu
        var isMobile = false; //initiate as false
        // device detection
        if(/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent) 
            || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(navigator.userAgent.substr(0,4))) { 
            isMobile = true;
        }
        if(!isMobile){
          $('li.menu-item.menu-item-has-children').click(function(event) {
            // parent click
            var link = $(this).find('>a').attr('href');
            if(link && link!=='#'){
              window.location.href = link;
            }
          });
          // menu hover
          $('li.menu-item.menu-item-has-children, .dropdown-menu').hover(function() {
            $(this).find('.dropdown-menu').addClass('show');
          }, function() {
            $(this).find('.dropdown-menu').removeClass('show');
          });
        }

        // Sticky Navbar
        $(window).scroll(function() {
          var nav = $('.navbar-daddy');
          var top = 150;
          if ($(window).scrollTop() >= top) {
            nav.addClass('fixed');
          } else {
            nav.removeClass('fixed');
          }
        });

        $('.partners .logos').slick({
          slidesToShow: 5,
          slidesToScroll: 2,
          arrows: true,
          draggable: true,
          autoplay: true,
          autoplaySpeed: 3000,
          nextArrow: '<div class="slick-next"></div>',
          prevArrow: '<div class="slick-prev"></div>',
          // On mobile
          responsive: [{
            breakpoint: 865,
            settings: {
              slidesToShow: 2,
            }
          }],
        });

        // disable number input scroll
        $(document).on("wheel", "input[type=number]", function (e) {
            $(this).blur();
        });

        
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
        $('.testimonial-carousel').slick({
          slidesToShow: 3,
          slidesToScroll: 3,
          dots: true,
          arrows: false,
          // On mobile
          responsive: [{
            breakpoint: 1200,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            }
          }],
        });
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS

      }
    },
    // About us page, note the change from about-us to about_us.
    'courses': {
      init: function() {
        var courseList = initList('course-list');
      }
    },
    // Submit Course page, note the change from about-us to about_us.
    'submit_course': {
      init: function() {

        $("body").on('keyup', '.acf-field[data-name=total_cost_of_materials] input[type=number].acf-is-prepended', creditsVerification );
        $("body").on('keyup', '.acf-field[data-name=number_of_credits] input[type=number]', creditsVerification );

        // disable auttofill
        $('input, select').attr('autocomplete', 'off');

      }
    },
    'edit_course': {
      init: function() {

        $("body").on('keyup', '.acf-field[data-name=total_cost_of_materials] input[type=number].acf-is-prepended', creditsVerification );
        $("body").on('keyup', '.acf-field[data-name=number_of_credits] input[type=number]', creditsVerification );

        // disable auttofill
        $('input, select').attr('autocomplete', 'off');

      }
    },
    'institution_dashboard': {
      init: function() {

        
        var Pending = initList('Pending');
        var Denied = initList('Denied');
        var Approved = initList('Approved');
        var Archived = initList('Archived');

      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.

(function($) {
    $.fn.changeElementType = function(newType) {
        var attrs = {};
        $.each(this[0].attributes, function(idx, attr) {
            attrs[attr.nodeName] = attr.nodeValue;
        });
        this.replaceWith(function() {
            return $("<" + newType + "/>", attrs).append($(this).contents());
        });
    }
})(jQuery);

(function($) {
    $.fn.switchToFakeLabel = function() {
      $(this).addClass('label');
      $(this).changeElementType('span');
      return this;
    }
})(jQuery);

function addScrollToTopOfListEvent( id ){
  $(document).ready(function() {
    // Scroll to top of list when changing page at bottom pagination
    $('#'+id+' .paginationBottom li:not(.active)').click( function(event) {
      event.preventDefault();
      $([document.documentElement, document.body]).animate({
          scrollTop: $('#'+id+' .paginationTop').offset().top
      }, 500);
    });
  });
}


$ = jQuery;
function initList( id ){

  // Init List.js
  var courseList = new List( id , {
    valueNames: ['institution', 'college', 'course', 'instructor', 'modality', 'term', { name: 'isbn', attr: 'data-isbn' } ],
    page: 25,
    pagination: [{
      paginationClass: "paginationTop",
      outerWindow: 1
    },
    {
      paginationClass: "paginationBottom",
      outerWindow: 1
    }]
  });

  // On List update: update count + Show/Hide no-results pane
  courseList.on('updated', function(list) {
    $('.totalCount .number').text(list.matchingItems.length.toString());
    if (list.matchingItems.length > 0) {
      $('.no-result').hide();
    } else {
      $('.no-result').show();
    }
    setTimeout(function (){
      // Something you want delayed.
      addScrollToTopOfListEvent( id );
    }, 500);
  });

  addScrollToTopOfListEvent( id );

  // Search functionality
  $( ".advanced-search input[type='text']" ).keyup(function() {
    $(this).prev('.remove').addClass('show');
    if($(this).data('searchtype')=='course'){
      $(this).val( $(this).val().trim() );
    }
    updateSearch( this );
  });

  $( ".advanced-search select" ).change(function() {
    $(this).prev('.remove').addClass('show');
    updateSearch( this );
  });

  // remove 'Other' option from advanced-search dropdown
  $(".advanced-search select[name=institution] option[value='Other']").remove();

  // Remove a filter functionality
  $( ".advanced-search .filter>.remove" ).click(function() {
    removeFilter( this );
  });

  function updateSearch( that ){
    var searchtype = $(that).data('searchtype');
    var query = $(that).val();
    console.log('------------------');
    console.log('Starting updateSearch()');
    console.log('-> searchType: ' + searchtype);
    console.log('-> query: ' + query);
    if(true){
      console.log(searchtype + ': ' + query);
      courseList.search( query , [ searchtype ]); // Only search in X column
      // apply any other active filters apart from the active search
      $('.advanced-search .custom-search:not(:focus)').each(function(name) {
        var filterName = $(this).data('searchtype');
        var filterValue = $(this).val() ? $(this).val().toLowerCase() : "";
        console.log(filterName + ': '+ filterValue);
        if(filterValue!==null && filterValue!==""){
          courseList.filter(function (item) {
            var currentItemValue = item.values()[filterName].toLowerCase();  
            var theResult = (currentItemValue.indexOf(filterValue) >= 0);
            return (currentItemValue.indexOf(filterValue) >= 0);
          });
        }
      });
    }else{
      courseList.search( '' , [ 'institution' ]); // reset search
      courseList.filter(); // Remove all filters
    }
  }

  function removeFilter( that ){
    // Hide the 'remove filter cross'
    $(that).removeClass('show'); 
    
    // Reset value of filter that we want to remove
    var filterToRemove = $(that).next();
    $(filterToRemove).val("");

    window.updateSearch = false;
    // are there any other filters applied?
    $('.advanced-search .custom-search').each(function(name) {
      var filterName = $(this).data('searchtype');
      var filterValue = $(this).val() ? $(this).val().toLowerCase() : "";
      if(filterValue!==null && filterValue!==""){
        // there is another filter -> updateSearch
        window.updateSearch = true;
      }
    });

    if(window.updateSearch){
      // Update search
      console.log('[UPDATE SEARCH]');
      updateSearch(filterToRemove);
    }else{
      // Reset search
      console.log('[RESET SEARCH]');
      courseList.search( '' , [ 'course' ]); // reset search
      courseList.filter(); // Remove all filters
    }
  }

  return courseList;
}

function creditsVerification() {
  var material_qty = $('input#acf-field_5cdc1e64844fe-0-field_5cdc1e78844ff').val();
  var material_value = $('input#acf-field_5cdc1e64844fe-0-field_5cdc1e8184500').val();
  if(material_qty && material_value){
    if( parseInt(material_value)/parseInt(material_qty) <= 20 ){
      $('.acf-field-5cdd516f5ef10').text('✅');
    }else{
      $('.acf-field-5cdd516f5ef10').text('❌');
    }
  }
}
